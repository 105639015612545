<template>
	<div class="detail">
		<ori-header />
		<div class="demo-detail">
			<div class="left">
				<div class="main">
					<div class="detail-top">
						<div class="title">
							<span class="name">Mate ID UI</span
							><span class="money">￥5000以下</span>
						</div>
						<div class="info">
							<ul>
								<li>竞标中</li>
								<li>项目</li>
								<li>视图设计类</li>
							</ul>
						</div>
					</div>
					<div class="detail-midder">
						<p>发布日期: <span>2022-04-22</span></p>
						<p>项目周期: <span>14天</span></p>
						<p>驻场需求: <span>定期驻场</span></p>
						<p>技能要求: <span>Logo</span></p>
						<p>需支持/适配的客户端: <span>网站</span></p>
						<p>合作倾向: <span>不限</span></p>
						<div class="btn">竞标报名</div>
					</div>
					<div class="detail-describe">
						<div class="title">需求描述</div>
						<p>1.首页设计</p>
						<p>2.Mate ID设计</p>
						<p>3.排行榜单设计</p>
						<p>4.Logo</p>
					</div>
					<div class="detail-attachment">
						<div class="title">附件</div>
						<p>请登陆后查看附件</p>
					</div>
					<div class="detail-footer">
						<div class="btn">竞标报名</div>
					</div>
				</div>
				<div class="list">
					<div class="list-title">报名列表</div>
					<div class="list-addly">
						<ul>
							<li v-for="i in 15" :key="i">
								<img src="@/assets/images/header-1.jpeg" alt="" />
								<p>艾斯视觉</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="right">
				<div class="main-user">
					<img src="@/assets/images/header-2.png" alt="" />
					<h5>Tagging</h5>
					<p>广东省 | 企业 | 好评率: --</p>
					<div class="user-icons">
						<img src="@/assets/images/cert.png" title="企业身份已认证" />
						<img src="@/assets/images/email.png" title="邮箱已认证" />
						<img src="@/assets/images/mobile.png" title="手机已认证" />
					</div>
					<div class="user-main">
						<div class="main">
							<div class="count">5</div>
							<div class="title">发布总数</div>
						</div>
						<div class="main">
							<div class="count">--</div>
							<div class="title">发包综合评价</div>
						</div>
					</div>
				</div>
				<img src="@/assets/images/member3.jpg" alt="" />
				<div class="main-large">
					<div class="main-large-top">
						提升中标率
					</div>
					<div class="main-large-content">
						<ul>
							<li>
								<span>1</span>
								<p>完善您的相关信息，尤其是技术能力与专业经验</p>
							</li>
							<li>
								<span>2</span>
								<p>
									认真详细阅读理解项目需求，认真详细填写竞标方案与您的竞标优势
								</p>
							</li>
							<li>
								<span>3</span>
								<p>
									我们拒绝恶意报价，一份真实且具有竞争力的报价无疑可以让您更容易中标
								</p>
							</li>
							<li>
								<span>4</span>
								<p>积极响应与发包方的沟通交流，体现良好的职业素养及专业能力</p>
							</li>
							<li>
								<span>5</span>
								<p>维护并保持您在平台的综合评价及信用等级良好</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<page-foot />
	</div>
</template>

<script>
import PageFoot from '../../../components/ori-com/PageFoot.vue'
export default {
	components: {
		PageFoot,
	},
}
</script>

<style scoped lang="scss">
.detail {
	background-color: #f7f7f7;
	ul {
		padding: 0;
		margin: 0;
		li {
			list-style: none;
		}
	}
	.demo-detail {
		width: 1200px;
		margin: auto;
		display: flex;
		padding-top: 20px;
		.left {
			flex: 3;
			margin-bottom: 40px;
			.main {
				margin-bottom: 20px;
				background-color: #fff;
				box-shadow: 0 0 4px 0 rgb(0 0 0 / 10%);
				padding-bottom: 20px;
				border-radius: 5px;
				.detail-top {
					padding: 20px;
					border-bottom: 1px solid #d7d7d7;
					.title {
						display: flex;
						justify-content: space-between;
						.name {
							font-size: 20px;
							font-weight: 700;
						}
						.money {
							font-size: 24px;
							font-weight: 700;
							color: #f30;
						}
					}
					.info {
						ul {
							display: flex;
							padding-bottom: 10px;
							li {
								display: inline-block;
								padding: 5px;
								background-color: rgba(239, 239, 244, 1);
								margin-right: 10px;
								font-size: 14px;
								border-radius: 5px;
							}
							li:nth-child(1) {
								background-color: #1890ff;
								color: #fff;
							}
						}
					}
				}
				.detail-midder {
					padding: 20px;
					font-size: 14px;
					position: relative;
					p {
						color: #999;
						margin: 0;
						padding: 5px;
						span {
							margin-left: 20px;
						}
					}
					.btn {
						width: 100px;
						height: 40px;
						background-color: #1890ff;
						color: #fff;
						position: absolute;
						border-radius: 5px;
						line-height: 40px;
						text-align: center;
						bottom: 30px;
						right: 20px;
						cursor: pointer;
					}
					.btn:hover {
						background-color: #f30;
					}
				}
				.detail-describe {
					padding: 0 20px;
					height: 300px;
					p {
						margin: 0;
						font-size: 14px;
            color: #666;
					}
				}
				.detail-attachment {
					padding: 20px;
					border-bottom: 1px solid #d7d7d7;
					p {
						font-size: 14px;
						margin: 0;
					}
				}
				.title {
					padding-left: 10px;
					border-left: 4px solid #1890ff;
					font-weight: 700;
					margin-bottom: 20px;
          color: #666;
				}
				.detail-footer {
					height: 60px;
					position: relative;
					.btn {
						width: 100px;
						height: 40px;
						background-color: #1890ff;
						color: #fff;
						border-radius: 5px;
						line-height: 40px;
						text-align: center;
						margin: 20px 20px 0 20px;
						cursor: pointer;
						position: absolute;
						top: 0;
						right: 0;
					}
					.btn:hover {
						background-color: #f30;
					}
				}
			}
			.list {
				background-color: #fff;
				box-shadow: 0 0 4px 0 rgb(0 0 0 / 10%);
				border-radius: 5px;
				.list-title {
					height: 45px;
					line-height: 45px;
					padding: 0 20px;
					font-weight: 700;
					border-bottom: 1px solid #d7d7d7;
				}
				.list-addly {
					padding: 40px 20px;
					ul {
						li {
							width: 100px;
							margin: 10px 2px;
							cursor: pointer;
							display: inline-block;
							text-align: center;
							img {
								width: 45px;
								height: 45px;
								border-radius: 45px;
							}
							p {
								font-size: 14px;
								margin-top: 10px;
							}
						}
            li:hover{
              color: #1890ff;
            }
					}
				}
			}
		}
		.right {
			flex: 1;
			// background-color: skyblue;
			margin-left: 20px;
			.main-user {
				height: 380px;
				text-align: center;
				background-color: #fff;
				box-shadow: 0 0 4px 0 rgb(0 0 0 / 10%);
				border-radius: 5px;
				position: relative;
				img {
					width: 90px;
					height: 90px;
					margin: 20px 0;
					cursor: pointer;
				}
				h5 {
					font-weight: 700;
					font-size: 18px;
					cursor: pointer;
				}
				h5:hover {
					color: #1890ff;
				}
				p {
					color: #999;
					font-size: 13px;
					line-height: 23px;
					margin: 10px 0;
				}
				.user-icons {
					padding-bottom: 40px;
					img {
						width: 18px;
						height: 18px;
						cursor: pointer;
						margin: 10px;
					}
				}
				.user-main {
					display: flex;
					background-color: rgba(245, 245, 245, 1);
					padding: 10px 0;
					position: absolute;
					bottom: 20px;
					right: 0;
					width: 100%;
					.main {
						flex: 1;
						.count {
							font-size: 30px;
						}
						.title {
							font-size: 13px;
							color: #999;
							line-height: 23px;
						}
					}
				}
			}
			img {
				width: 100%;
				margin: 20px 0 40px 0;
        cursor: pointer;
			}
			.main-large {
				background-color: #fff;
				box-shadow: 0 0 4px 0 rgb(0 0 0 / 10%);
				border-radius: 5px;
				.main-large-top {
					height: 45px;
					padding-left: 20px;
					line-height: 45px;
					font-weight: 700;
					border-bottom: 1px solid #d7d7d7;
				}
				.main-large-content {
					ul {
						padding: 20px;
						li {
							position: relative;
							span {
								position: absolute;
								top: 3px;
								width: 20px;
								height: 20px;
								color: #fff;
								line-height: 20px;
								text-align: center;
								display: block;
								background: #7abfff;
								border-radius: 10px;
							}
							p {
								line-height: 19px;
                color: #666;
								margin-left: 30px;
								margin-right: 0;
								font-size: 13px;
							}
						}
					}
				}
			}
		}
	}
}
</style>
